"use client";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { RE_CAPTCHA_ENTERPRISE_KEY, firebaseApiWidget } from "../config";
import { AppCheck, ReCaptchaEnterpriseProvider, initializeAppCheck } from "firebase/app-check";
import { getStorage } from "firebase/storage";

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

// IMPORTANT: This is the only copy of this interface from shared folder to encapsulate the dependency

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
export const widgetFirebaseApp = initializeApp(firebaseApiWidget);

// IMPORTANT: Comment out for bundle
export let appCheckInit: AppCheck | undefined = undefined;

if (typeof document !== "undefined") {
  appCheckInit = initializeAppCheck(widgetFirebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(RE_CAPTCHA_ENTERPRISE_KEY || ""),
    isTokenAutoRefreshEnabled: true,
  });
}

export const widgetDB = getFirestore(widgetFirebaseApp);

export const widgetAuth = getAuth(widgetFirebaseApp);

const widgetFunctions = getFunctions(widgetFirebaseApp);

export const widgetStorage = getStorage(widgetFirebaseApp);


 interface GetResponseRequest {
   assistantId?: string;
   webAppId?: string;
   widgetId?: string;
   chatId: string;
 }

interface GetResponseResponse {}

export const getResponse = httpsCallable<GetResponseRequest, GetResponseResponse>(
  widgetFunctions,
  "getResponse",
  {
    timeout: 40000,
  }
);

export const getResponsePRO = httpsCallable<GetResponseRequest, GetResponseResponse>(
  widgetFunctions,
  "getResponsePRO",
  {
    timeout: 60000,
  }
);

export const getCustomerPortalUrl = httpsCallable(widgetFunctions, "getCustomerPortalUrl");


export const generateTranscription = httpsCallable<any, any>(
  widgetFunctions,
  "generateTranscription"
);