import React, { useCallback, useEffect } from "react";

import { useContext, useState } from "react";
// @mui
import {
  InputBase,
  IconButton,
  useTheme,
  Stack,
  Box,
  LinearProgress,
  CircularProgress,
  Typography,
} from "@mui/material";
// components
import { ChatContext } from "../context";
import { useWidgetSettingsContext } from "../settings";
import Iconify from "../components/iconify";
import { uploadVoiceInput } from "../lib/storage";
import { generateTranscription } from "../lib/firebase";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useBoolean } from "../hooks/use-boolean";
import MediaRecorder from "./Polyfill/index.js";
import { collection, doc, setDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

// ----------------------------------------------------------------------

const planMessageCount = {
  price_1PTRURERYpQbaFQQuPcJwYiO: 30,
  price_1PTRTWERYpQbaFQQKEyLyxyl: 2500,
  price_1PaIEXERYpQbaFQQtlYfdbgc: 2500,
  price_1PTRUTERYpQbaFQQt5ZlagKB: 6000,
  price_1PaIJMERYpQbaFQQChyd9yei: 6000,
  price_1PTRUOERYpQbaFQQrF46V32Y: 12000,
  price_1PaIL0ERYpQbaFQQT9A8GZeB: 12000,
  price_1PTRUQERYpQbaFQQEAISy0Kc: 50000,
  price_1PaIMPERYpQbaFQQQV7MqVdW: 50000,
};

export default function ChatMessageInput() {
  const [message, setMessage] = useState('');
  const {
    status,
    sendMessage,
    isSubmittedFirstMessage,
    setIsSubmittedFirstMessage,
    chatId,
    messages,
  } = useContext(ChatContext);
  const settings = useWidgetSettingsContext();

  const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter' && status !== 'typing') {
      if (message) {
        if (settings.assistant) {
          sendMessage(message, messages);
        } else {
          settings.setSettings('errorSelectingAssistant', true);
        }
      }
      setMessage('');
    }
  };

  const handleClickSend = () => {
    if (message) {
      if (settings.assistant) {
        sendMessage(message, messages);
      } else {
        settings.setSettings('errorSelectingAssistant', true);
      }
    }
    setMessage('');
  };

  const [isTranscribing, setIsTranscribing] = useState(false);
  const db = useFirestore();

  const userRef = doc(db, 'users', settings.ownerUid || '1');

  const { data: user } = useFirestoreDocData(userRef);

  const isFreeUser =
    user?.stripe?.priceId === 'price_1PTRURERYpQbaFQQuPcJwYiO' ||
    !user?.stripe?.priceId;

  const userLeadsCount = user?.leads?.count || 0;

  const userReachLeadLimit = userLeadsCount > 2;

  const limitReached =
    user?.stripe?.priceId === 'price_1PTRUQERYpQbaFQQEAISy0Kc' ||
    user?.stripe?.priceId === 'price_1PaIMPERYpQbaFQQQV7MqVdW'
      ? false
      : (user?.messages?.count || 0) >
        ((planMessageCount as any)[
          user?.stripe?.priceId || 'price_1PTRURERYpQbaFQQuPcJwYiO'
        ] as any) +
          (user?.stripe?.extraMessages?.count || 0) * 2000;

  const getTranscriptions = async (blob: Blob, chatId?: string) => {
    try {
      const filePath = await uploadVoiceInput({
        file: blob,
        widgetId: settings.widgetId,
        chatId: 'test-assistant',
      });

      await generateTranscription({
        filePath,
        widgetId: settings.widgetId,
      }).then((res) => {
        const text = res.data.text;
        if (text) {
          if (settings.assistant) {
            sendMessage(text, messages);
          } else {
            settings.setSettings('errorSelectingAssistant', true);
          }
          recorderControls.recordingBlob = undefined;
        }
      });
    } catch (error) {
    } finally {
      setIsTranscribing(false);
    }
  };

  const recorderControls = useAudioRecorder();

  const theme = useTheme();

  const isHideRecord = useBoolean(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && !window?.MediaRecorder) {
      window.MediaRecorder = MediaRecorder as any;
    } else if (!window?.MediaRecorder) {
      isHideRecord.onTrue();
    }
  }, [MediaRecorder, typeof window]);

  useEffect(() => {
    let visualizer = document.querySelector(
      '.audio-recorder-visualizer canvas',
    ) as HTMLCanvasElement;

    const interval = setInterval(() => {
      visualizer = document.querySelector(
        '.audio-recorder-visualizer canvas',
      ) as HTMLCanvasElement;
      if (visualizer) {
        visualizer.width = 600;
        visualizer.height = 100;
        clearInterval(interval);
      }
    }, 100);
  }, [recorderControls.isRecording]);

  useEffect(() => {
    if (!recorderControls.recordingBlob) return;
    setIsTranscribing(true);
    getTranscriptions(recorderControls.recordingBlob, chatId as any);
  }, [recorderControls.recordingBlob]);

  const [isSentLimitMessage, setIsSentLimitMessage] = useState(false);

  useEffect(() => {
    const sentOwnerMessage = async () => {
      try {
        if (!isSentLimitMessage) {
          const mailDoc = doc(collection(db, 'new-leads-mail'));
          setIsSentLimitMessage(true);

          await setDoc(mailDoc, {
            to: [user?.email],
            message: {
              subject: 'Message Limit Reached',
              html: `<h4>Hi ${user?.firstName},</h4><p>Customers are trying to reach you, but you have reached the message credits limit.
              <p>
              In the meantime that Ai chatbot will display:
              <div>
              <code style="color: red">We are unavailable at the moment. Please check again later</code>
              <div>
              </p>
              <p>
              To stay in touch with your customers please consider <a href="https://www.platformconnection.com/pricing" target="_blank">upgrading</a> your plan.
              </p>
              <p>In any case starting next month the message counter will reset.</p>
              <p>Thank you</p>
              `,
            },
          });
        }
      } catch (error) {
        console.log('sentOwnerMessage limit reached', error);
        setIsSentLimitMessage(false);
      }
    };

    if (limitReached && user?.email && !isFreeUser) {
      sentOwnerMessage();
    }
  }, [limitReached, isSentLimitMessage, user?.email, isFreeUser]);

  return (
    <Box
      sx={{
        width: 1,
        display: 'flex',
        justifyContent: 'center',
      }}
      dir={settings.direction === 'rtl' ? 'rtl' : 'ltr'}
    >
      {limitReached || (isFreeUser && userReachLeadLimit) ? (
        <Stack>
          <Typography
            textAlign="center"
            color="error"
            sx={{
              width: 1,
              p: 2,
            }}
            variant="subtitle2"
          >
            We are unavailable at the moment. Please check again later
          </Typography>
        </Stack>
      ) : recorderControls.isRecording ? (
        <Stack
          direction={settings.direction === 'rtl' ? 'row-reverse' : 'row'}
          alignItems="center"
          sx={{
            flex: 1,
            '& .audio-recorder-mic, .audio-recorder-options': {
              display: 'none',
            },
            '& .audio-recorder-timer': {
              // fontFamily: primaryFont.style.fontFamily,
              color: theme.palette.text.primary,
            },
            '& .audio-recorder': {
              boxShadow: 'none',
              p: 1,
              pr: 3,
              width: 1,
              border: 'none',
              borderRadius: 0,
              height: 56,
              bgcolor: theme.palette.background.paper,
            },
            '& .audio-recorder-visualizer canvas': {
              width: 1,
              height: 30,
            },
          }}
        >
          <AudioRecorder
            recorderControls={recorderControls}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            onNotAllowedOrFound={(err) => console.table(err)}
            downloadFileExtension="mp3"
            showVisualizer
          />
          <IconButton
            draggable="true"
            size="small"
            onClick={recorderControls.stopRecording}
            sx={{ mr: 3 }}
          >
            <Iconify icon="icon-park-outline:voice-off" width={23} />
          </IconButton>
        </Stack>
      ) : (
        <InputBase
          disabled={isTranscribing}
          value={isTranscribing ? 'Transcribing' : message}
          onKeyUp={handleSend}
          onChange={(event) => setMessage(event.target.value)}
          placeholder={settings.inputPlaceholder}
          startAdornment={
            settings.direction === 'rtl' && (
              <Stack direction="row" sx={{ flexShrink: 0 }}>
                {!recorderControls.isRecording && !isHideRecord.value && (
                  <Stack
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      disabled={isTranscribing || status === 'typing'}
                      draggable="true"
                      size="small"
                      onClick={() => {
                        recorderControls.startRecording();
                      }}
                      sx={{ mr: 1 }}
                    >
                      <Iconify icon="icon-park-solid:voice" width={20} />
                    </IconButton>
                  </Stack>
                )}
                <IconButton
                  disabled={status === 'typing'}
                  size="medium"
                  onClick={handleClickSend}
                  sx={{}}
                >
                  <Iconify icon="streamline:mail-send-email-message-solid" />
                </IconButton>
              </Stack>
            )
          }
          endAdornment={
            settings.direction !== 'rtl' && (
              <Stack direction="row" sx={{ flexShrink: 0 }}>
                {!recorderControls.isRecording && !isHideRecord.value && (
                  <Stack
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      disabled={isTranscribing || status === 'typing'}
                      draggable="true"
                      size="small"
                      onClick={() => {
                        recorderControls.startRecording();
                      }}
                      sx={{ mr: 1 }}
                    >
                      <Iconify icon="icon-park-solid:voice" width={20} />
                    </IconButton>
                  </Stack>
                )}
                <IconButton
                  disabled={status === 'typing'}
                  size="medium"
                  onClick={handleClickSend}
                  sx={{}}
                >
                  <Iconify icon="streamline:mail-send-email-message-solid" />
                </IconButton>
              </Stack>
            )
          }
          sx={{
            px: 2,
            height: 56,
            flex: 1,
            flexShrink: 0,
            borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
            '& input::placeholder': {
              fontWeight: 500,
            },
          }}
        />
      )}
    </Box>
  );
}
