// @mui
import React, { useContext } from "react";
import { Box, Card, Link, Stack, Typography, alpha, useTheme } from "@mui/material";
// data
// components
import ChatMessageInput from "./message/ChatMessageInput";
import ChatHeaderDetail from "./header/ChatHeaderDetail";
import ChatMessageList from "./message/ChatMessageList";
import { useWidgetSettingsContext } from "./settings";
import { ChatContext } from "./context";
import { useBoolean } from "./hooks/use-boolean";
import { CustomAvatar } from "./components/custom-avatar";
import { DEFAULT_LOGO } from "./lib/firestore";
import { LeadInfoForm } from "./components/LeadInfoForm";

// ----------------------------------------------------------------------

export default function Chat() {
  const settings = useWidgetSettingsContext();
  const chat = useContext(ChatContext);
  const theme = useTheme();

  const loading = useBoolean(false);


  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {/* <CustomAvatar
        src={(settings.assistantAvatar as any)?.preview || DEFAULT_LOGO}
        sx={{
          background: "none",
          width: 80,
          height: 80,
          position: "absolute",
          zIndex: 999,
          top: -8,
          left: -20,
        }}
        alt={settings.name}
        name={settings.name}
        // BadgeProps={{
        //   badgeContent: <BadgeStatus status={status} />,
        // }}
      /> */}
      <Card
        id="widget-height"
        sx={{
          [theme.breakpoints.down("sm")]: {
            borderRadius: 0,
          },
          display: "flex",
          boxShadow: (theme) => theme.customShadows.card,
          backgroundColor: "transparent",

          height: {
            xs: "100dvh",
            sm: `calc(100vh - 186px - ${settings.bottomSpacing}px)`,
            md: `calc(75vh - ${settings.bottomSpacing}px)`,
          },
        }}
      >
        <Stack
          flexGrow={1}
          sx={{
            overflow: "hidden",
            // backgroundColor: theme.palette.background.paper,
            borderRadius: {
              xs: 0,
              sm: 2,
            },
          }}
        >
          <ChatHeaderDetail />
          <Stack
            direction="row"
            flexGrow={1}
            sx={{
              overflow: "hidden",
              borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
              backgroundColor: "background.paper",
            }}
          >
            <Stack flexGrow={1} sx={{ minWidth: 0, position: "relative" }}>
              {chat.chatId || !chat.isSubmittedFirstMessage ? (
                <>
                  <ChatMessageList />
                  <ChatMessageInput />
                </>
              ) : (
                <LeadInfoForm />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Card>
      {(settings.plan ? settings.poweredBy : true) && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 999,
            bottom: 0,
            boxShadow: (theme) => theme.shadows[4],
            left: "50%",
            transform: "translate(-50%, calc(100% + 8px))",
            px: 1,
            py: 0.5,
            backgroundColor: "background.paper",
            borderRadius: 2,
          }}
        >
          <Stack direction="row" gap={0.75} alignItems="center">
            <Link href="https://platformconnection.com" target="_blank">
              <Typography variant="subtitle2" color="text.secondary" fontSize={14} noWrap>
                Powered By Platform Connection
              </Typography>
            </Link>

            <Typography fontSize={14} variant="subtitle2" color="text.secondary">
              <Link href="" target="_blank"></Link>
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
