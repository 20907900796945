import React from "react";

// @mui
import {
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Box,
  alpha,
  useTheme,
  SvgIcon,
} from "@mui/material";
// utils
import { useContext } from "react";
// @types
// components
import BadgeStatus from "../components/badge-status";
import { CustomAvatar } from "../components/custom-avatar";
import { ChatContext } from "../context";
import { bgGradient } from "../theme/css";
import { useWidgetSettingsContext } from "../settings";
import Iconify from "../components/iconify";
import chroma from "chroma-js";
import { DEFAULT_LOGO } from "../lib/firestore";

// ----------------------------------------------------------------------

export default function ChatHeaderDetail() {
  const { setChatOpen, clearConversation } = useContext(ChatContext);
  const theme = useTheme();

  const settings = useWidgetSettingsContext();
  const { status } = useContext(ChatContext);

  const isAnimated =
    (!settings.assistantAvatar as any)?.preview ||
    (settings.assistantAvatar as any)?.preview === DEFAULT_LOGO;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: (theme) => theme.spacing(2),
        bgcolor: "background.paper",
        ...bgGradient({
          direction: "0deg",
          startColor: alpha(theme.palette.primary.main, 0.8),
          endColor: alpha(
            theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.main,
            1
          ),
        }),
        position: "relative",
        // backgroundColor: "common.white",
      }}
    >
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          width={1}
          sx={{
            minHeight: 32,
          }}
        >
          <CustomAvatar
            src={
              isAnimated
                ? "https://firebasestorage.googleapis.com/v0/b/chat-bot-ai-147ba.appspot.com/o/default.png?alt=media&token=9a9263b4-5d30-415e-941a-8ebc2f1ff0aa"
                : (settings.assistantAvatar as any)?.preview || DEFAULT_LOGO
            }
            sx={{
              width: 100,
              height: 100,
              position: "absolute",
              pointerEvents: "none",
              top: 12,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 999,
              background: "transparent",
            }}
            alt={settings.name}
            name={settings.name}
          />

          <Box
            sx={{
              height: 60,
              width: 1,
              opacity: 0.9,
              background: `linear-gradient(180deg, ${alpha(theme.palette.primary.main, 0.5)} 0%, ${
                settings.themeMode === "dark"
                  ? theme.palette.background.paper
                  : "rgba(255,255,255,1)"
              } 100%)`,
              backdropFilter: "blur(30px)",
              position: "absolute",
              bottom: -60,
              zIndex: 998,
            }}
          />

          <Box sx={{ position: "absolute", top: 5, right: 5 }}>
            {/* <Tooltip title="Close the chat"> */}
            <IconButton
              onClick={() => setChatOpen(false)}
              sx={{
                p: 0.5,
              }}
            >
              <Iconify
                sx={{
                  color:
                    chroma(theme.palette.primary.main || "white").luminance() > 0.4
                      ? "#000"
                      : "white",
                }}
                width={26}
                icon="iconamoon:close-bold"
              />
            </IconButton>
            {/* </Tooltip> */}
          </Box>
          <Box sx={{ position: "absolute", top: 5, left: 5, zIndex: 999 }}>
            <Tooltip title="Clear the conversation">
              <IconButton
                onClick={clearConversation}
                sx={{
                  p: 1,
                }}
              >
                <Iconify
                  sx={{
                    color: "warning.main",
                  }}
                  width={20}
                  icon="carbon:clean"
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
