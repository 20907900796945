import React from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormProvider, { RHFTextField } from "./hook-form";
import { Box, Button, CircularProgress, InputAdornment, Stack, Typography } from "@mui/material";
import { ChatContext } from "../context";

export const LeadInfoForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setLeadInfo } = useContext(ChatContext);
  const phoneRegExp = /^[0-9]{10,15}$/;
  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email must be a valid email address"),
    username: Yup.string().required("Username is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number must be between 10 and 15 digits")
      .required("Phone is required"),
  });

  const defaultValues = {
    email: "",
    username: "",
    phone: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema) as any,
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    try {
      setLeadInfo(data);
    } catch (error) {
      console.error(error);
    }
  });
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        padding: 2,
        py: 9,
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <FormProvider
          methods={methods}
          onSubmit={onSubmit}
          style={{
            width: "100%",
          }}
        >
          <Stack spacing={2} alignSelf={"center"}>
            <Typography variant="subtitle1" textAlign="center">
              Please introduce yourself
            </Typography>
            <RHFTextField name="username" label="Full Name" />
            <RHFTextField name="email" label="Email" />

            <RHFTextField name="phone" label="Phone" />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Stack>
        </FormProvider>
      )}
    </Stack>
  );
};
